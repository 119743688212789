<template>
	<ShutterPanel />
</template>


<script type="text/javascript">
    import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterContract from '@/mixins/shutters-manager/Contract.js'

	export default {
        name: "ContractShutter",
        mixins: [Shutter, ShutterContract],
        props: ["contract_id", "from", "version"],
		data () {
			return {
			}
        },
        created() {
            if(this.$route.params.acte_id) {
                this.actes_ids_route = this.$route.params.acte_id.toString()
            }
            if(this.$route.params.horse_id) {
                this.horse_ids_route = this.$route.params.horse_id.toString()
            }
            if(this.$route.params.state) {
                this.usage = this.$route.params.state
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                if(this.contract_id) {
				    this.setupContractForm(this.contract_id, this.from)
                }
                else {
                    this.setupContractList()
                }
            },

            onShuttersSubmit() {
                this.redirectPostSave()
            },

            redirectPostSave() {
                const from = this.$route.params.from
                const horse_id = this.$route.params.horse_id

                switch (from) {
                    case 'HorseFicheActes' :
                        this.$router.push({ name: from, params: horse_id })
                        break
                    case 'HorseFichePlanning' :
                        this.$router.push({ name: from, params: horse_id })
                        break
                    default :
                        this.$router.push({ name: from })
                }
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ShutterPanel
		},
    }
</script>
